import React from 'react'
import { Link } from 'gatsby'

// Components
import Layout from '../../components/layout.js'

const DeckIndex = ({ pageContext }) => {
  const decks = pageContext.decks.map(d => d.node)

  return (
    <Layout title="Talks">
      <article>
        <h1>All Talks</h1>
        <ul>
          {decks.map(({ title, slug: path }) => {
            return (
              <li key={path}>
                <Link to={path}>{title}</Link>
              </li>
            )
          })}
        </ul>
      </article>
    </Layout>
  )
}

export default DeckIndex
